import invariant from 'invariant';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { computed, makeObservable, override } from 'mobx';
import moment from 'moment';
import { getAddressLabel } from 'src/components/common/address-label';
import DocumentsUIState from 'src/components/transactions/documents/documents-ui-state';
import { getOrCreateOffer } from 'src/models/transactions/intents';
import {
  getDetails,
  setPackagesViewedInTransaction,
} from 'src/models/transactions/items/transaction-package';
import type TransactionPackage from 'src/models/transactions/items/transaction-package';
import formatCurrency, { currencyToNumber } from 'src/utils/format-currency';
import formatPercentage from 'src/utils/format-percentage';
import formatTimestamp from 'src/utils/format-timestamp';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';
import getStateConfig from 'src/utils/get-state-config';
import { TIMEZONES_BY_STATE } from 'src/utils/states';
import Item, { ItemStore, TransactionItemJson } from './item';

// Summary term keys
export const SUMMARY_TERMS = {
  SUBMITTED_AT: 'submitted-at',
  BUYERS: 'buyers',
  PURCHASE_PRICE: 'purchase-price',
  FINANCING_TYPE: 'financing-type',
  LOAN_AMOUNT: 'loan-amount',
  DOWN_PAYMENT: 'down-payment',
  INITIAL_DEPOSIT: 'initial-deposit',
  INCREASED_DEPOSIT: 'increased-deposit',
  CLOSE_OF_ESCROW: 'close-of-escrow',
  EXPIRATION_DATE: 'expiration-date',
  ADDITIONAL_FINANCING: 'additional-financing',
  OTHER: 'other',
};

export const CONTINGENCY_TERMS = {
  LOAN: 'loan-contingency',
  APPRAISAL: 'appraisal-contingency',
  DISCLOSURE_REVIEW: 'disclosure-preview-contingency',
  BUYER_PROPERTY_SALE: 'buyer-property-sale-contingency',
  INSPECTIONS: 'inspection-contingency',
};

const {
  SUBMITTED_AT,
  BUYERS,
  PURCHASE_PRICE,
  FINANCING_TYPE,
  LOAN_AMOUNT,
  DOWN_PAYMENT,
  INITIAL_DEPOSIT,
  CLOSE_OF_ESCROW,
  EXPIRATION_DATE,
  ADDITIONAL_FINANCING,
  OTHER,
} = SUMMARY_TERMS;

export const SUMMARY_TERMS_BY_STATE = {
  CA: [
    SUBMITTED_AT,
    BUYERS,
    PURCHASE_PRICE,
    FINANCING_TYPE,
    LOAN_AMOUNT,
    DOWN_PAYMENT,
    INITIAL_DEPOSIT,
    CLOSE_OF_ESCROW,
    EXPIRATION_DATE,
    ADDITIONAL_FINANCING,
    OTHER,
  ],
  __default: [
    SUBMITTED_AT,
    BUYERS,
    PURCHASE_PRICE,
    FINANCING_TYPE,
    LOAN_AMOUNT,
    DOWN_PAYMENT,
    INITIAL_DEPOSIT,
    CLOSE_OF_ESCROW,
    ADDITIONAL_FINANCING,
    OTHER,
  ],
};

export async function createOffer({
  transactions,
  router,
  transaction,
  formIds,
  tdIds,
  propertyInfoId,
}: any) {
  invariant(!formIds || !tdIds, 'Cannot set formIds and tdIds simultaneously.');
  const isSaleOrPropertyOffer = transaction.isSale || propertyInfoId;
  const {
    result: { offer_id: offerId },
  } = await transactions.dispatch(
    transaction.id,
    getOrCreateOffer({
      formIds,
      tdIds,
      propertyInfoId,
    })
  );
  router.navigate(
    `transactions.transaction.offers${
      isSaleOrPropertyOffer ? '.offer' : ''
    }.prepare`,
    {
      ...router.route.params,
      ...(isSaleOrPropertyOffer
        ? {
            offerId,
          }
        : {}),
    }
  );
}

export function setOffersViewedInTransaction(offers: Offer[]) {
  const packages = (offers || [])
    .map((o) => o.package)
    .filter((tp) => tp?.transactionId);
  if (packages.length) {
    setPackagesViewedInTransaction(packages);
  }
}

export const FINANCING_TYPES = {
  CASH: 'Cash',
  LOAN: 'Loan',
  UNKNOWN: '-',
};

export const LOAN_TYPES = {
  CONVENTIONAL: 'Conventional',
  FHA: 'FHA',
  VA: 'VA',
  SELLER_FINANCING: 'Seller Financing',
  ASSUMED_FINANCING: 'Assumed Financing',
  OTHER: 'Other',
};

export const FLORIDA_CONTINGENCIES = [
  {
    key: 'INSPECTIONS',
    fieldId: 'inspections',
    title: 'Inspection Contingency',
  },
  {
    key: 'APPRAISAL',
    fieldId: 'appraisal',
    title: 'Appraisal Contingency',
  },
  {
    key: 'LOAN',
    fieldId: 'loan',
    title: 'Loan Contingency',
  },
  {
    key: 'DISCLOSURE_REVIEW',
    fieldId: 'disclosure_review',
    title: 'Discl. and Reports Delivered to Buyer',
  },
  {
    key: 'BUYER_PROPERTY_SALE',
    fieldId: 'buyer_property_sale',
    title: 'Sale of Buyer’s Property',
  },
];

const CALIFORNIA_CONTINGENCIES = FLORIDA_CONTINGENCIES.map((contingency) => ({
  ...contingency,
  title:
    contingency.key === 'INSPECTIONS'
      ? 'Investigation of Property Contingency'
      : contingency.title,
}));

export const CONTINGENCIES_BY_STATE = {
  CA: CALIFORNIA_CONTINGENCIES,
  FL: FLORIDA_CONTINGENCIES,
  __default: FLORIDA_CONTINGENCIES,
};

export const MAP_FORM_TERM = {
  'Inspection Contingency': { CA: 'Investigation of Property Contingency' },
  'Disclosures and Reports Delivered to Buyer Contingency': {
    CA: 'Review of Seller Documents Contingency',
  },
};

// Decision Statuses
export const DRAFT = 'DRAFT';
export const SUBMITTED = 'SUBMITTED';
export const PENDING = 'PENDING';
export const ACCEPTED = 'ACCEPTED';
export const REJECTED = 'REJECTED';
export const COUNTERED = 'COUNTERED';
export const VOIDED = 'VOIDED';

export class Amount {
  amount?: number;
  total?: number;
  options?: any;

  constructor(
    amount?: string | number | null,
    total?: string | number | null,
    options?: any
  ) {
    makeObservable(this);
    const strAmount = String(amount);
    const strTotal = String(total);
    this.amount = !Number.isNaN(parseFloat(strAmount))
      ? parseFloat(strAmount)
      : undefined;
    this.total = !Number.isNaN(parseFloat(strTotal))
      ? parseFloat(strTotal)
      : undefined;
    this.options = {
      amountPrecision: 2,
      percentagePrecision: 1,
      ...options,
    };
  }

  @computed
  get isSet() {
    return this.amount !== undefined;
  }

  @computed
  get formatted() {
    return formatCurrency(this.amount as number, {
      precision: this.options.amountPrecision,
    });
  }

  @computed
  get percentage() {
    return this.total && this.amount
      ? (this.amount * 100.0) / this.total
      : undefined;
  }

  getFormattedPercentage = (options = {}) => {
    return formatPercentage(this.percentage as number, {
      precision: this.options.percentagePrecision,
      ...options,
    });
  };

  @computed
  get formattedPercentage() {
    return this.getFormattedPercentage().replace('.0%', '%');
  }
}
export class RelativeDate {
  fixedTs?: number;
  daysFrom?: number;
  options: {
    empty: string;
    tzName?: string;
    tzSuffix?: string;
  };
  constructor(
    fixedTs?: number | string,
    daysFrom?: number | string,
    options?: {
      empty?: string;
      tzName?: string;
      tzSuffix?: string;
    }
  ) {
    makeObservable(this);
    this.fixedTs = fixedTs ? +fixedTs : undefined;
    this.daysFrom = daysFrom ? +daysFrom : undefined;
    this.options = {
      empty: '--',
      ...options,
    };
  }

  @computed
  get isSet() {
    return Boolean(this.fixedTs || this.daysFrom);
  }

  @computed
  get isFixed() {
    return Boolean(this.fixedTs);
  }

  @computed
  get dateMoment() {
    if (!this.fixedTs) {
      return undefined;
    }
    const dateMoment = moment(this.fixedTs);
    return dateMoment?.isValid() && this.options?.tzName
      ? dateMoment.tz(this.options.tzName)
      : dateMoment;
  }

  getDate = (format: string, empty?: string, withTimeZone = true) => {
    const formattedDateMoment =
      this.dateMoment && this.dateMoment.format(format);
    return formattedDateMoment
      ? `${formattedDateMoment} ${
          (withTimeZone && this.options?.tzSuffix) || ''
        }`.trim()
      : empty ?? this.options.empty;
  };

  getDaysFromStr(empty?: string) {
    return this.daysFrom
      ? `${this.daysFrom} day${this.daysFrom !== 1 ? 's' : ''}`
      : empty ?? this.options.empty;
  }

  getFormattedStr(dateFormat: string, empty?: string, withTimeZone = true) {
    return this.dateMoment
      ? this.getDate(dateFormat, empty, withTimeZone)
      : this.getDaysFromStr(empty);
  }
}

export const DEFAULT_COMPARATOR = (p: any, o: any) => isEqual(p, o);

export const AMOUNT_COMPARATOR = (p: any, o: any) => {
  return p.amount === o.amount;
};

export const DATE_COMPARATOR = (p: any, o: any) => {
  return (
    (p.daysFrom || 0) === (o.daysFrom || 0) &&
    ((Number.isNaN(p.fixedTs) && Number.isNaN(o.fixedTs)) ||
      p.fixedTs === o.fixedTs)
  );
};

export const TERM_COMPARATORS = {
  default: DEFAULT_COMPARATOR,
  price: AMOUNT_COMPARATOR,
  downPayment: AMOUNT_COMPARATOR,
  initialDeposit: AMOUNT_COMPARATOR,
  loanAmount: AMOUNT_COMPARATOR,
  closeOfEscrow: DATE_COMPARATOR,
  escrowHolderInfo: (p: any, o: any) => {
    return p.acceptSellersPreferred === o.acceptSellersPreferred;
  },
  contingencies: (p: any, o: any) => {
    const fields = [
      'fixedDate',
      'daysFromAcceptance',
      'type',
      'typeDesc',
      'waived',
    ];

    return fields.every((f) => p[f] === o[f]);
  },
};

export type OfferJson = TransactionItemJson<'OFFER'>;

export default class Offer extends Item<'OFFER'> {
  constructor(store: ItemStore, json: OfferJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get packageId() {
    return this.kindItem.packageId || '';
  }

  get package() {
    return this.store.getItem(
      this.transaction.id,
      'TRANSACTION_PACKAGE',
      this.packageId
    ) as TransactionPackage;
  }

  @computed
  get allOfferPackages() {
    // Note: Make sure to refetch all offer packages
    const allPackages = this.store.getItems(
      this.transaction.id,
      'TRANSACTION_PACKAGE'
    ) as TransactionPackage[];
    return allPackages.filter((tp) => tp.packageKind === 'OFFER');
  }

  @computed
  get tdIds() {
    return this.package?.activeTdIds || [];
  }

  @computed
  get tds() {
    return this.package?.activeTds || [];
  }

  @computed
  get reState() {
    return this.kindItem.reState || '';
  }

  @computed
  get tz() {
    return (
      TIMEZONES_BY_STATE[this.reState as keyof typeof TIMEZONES_BY_STATE] || {}
    );
  }

  @computed
  get tzName() {
    return this.tz?.name;
  }

  @computed
  get tzSuffix() {
    return this.tz?.suffix;
  }

  @computed
  get flowId() {
    return this.kindItem.flowId;
  }

  @computed
  get offerData() {
    return this.kindItem.formattedOfferData;
  }

  @computed
  get transactionSide() {
    return this.kindItem.transactionSide;
  }

  @computed
  get status() {
    const pendingActionStatus = this.package?.pendingActionStatus;
    return pendingActionStatus || this.kindItem.status;
  }

  get features() {
    return this.store.parent.features;
  }

  get isFlexibleReviseOfferFlowEnabled() {
    const state = this.reState;
    const stateConfig = getStateConfig(state);
    const isEmbedded = Boolean(this.store.parent.embeddedApp?.isEmbedded);
    return isEmbedded || Boolean(stateConfig.flexible_revise_offer_flow);
  }

  @computed
  get canDecide() {
    return (
      this.submitted.isSet &&
      this.pending &&
      (this.package.packageStatus === 'COUNTERED' || this.transaction.isListing)
    );
  }

  decisions(uiState: DocumentsUIState) {
    return (
      getDetails(this.package, {
        router: this.store.parent.router,
        features: this.store.parent.features,
        ui: this.store.parent.ui,
        uiState,
      }).actions || []
    );
  }

  @computed
  get canNegotiate() {
    return !this.allOfferPackages.some(
      (tp) => tp.submittedAction?.actionType === 'ACCEPT'
    );
  }

  @computed
  // "final" states are the end of normal offer workflows. Practically it just means a
  // user must "undo" the final state in order to change to another status.
  get isInFinalState() {
    return new Set([ACCEPTED, REJECTED, VOIDED]).has(this.status);
  }

  @computed
  get isAccepted() {
    return this.status === ACCEPTED;
  }

  @computed
  get undoAction() {
    return (
      {
        [ACCEPTED]: 'Unaccept',
        [REJECTED]: 'Unreject',
        [VOIDED]: 'Uncancel',
      }[this.status] || null
    );
  }

  @computed
  get showCounterWarning() {
    return (
      this.isSale &&
      this.allOfferPackages.some(
        (tp) => tp.submittedAction?.counterKind === 'SELLER_INDIVIDUAL'
      )
    );
  }

  @computed
  get disabledCounterMessage() {
    if (this.package.submittedAction?.actionType === 'COUNTER_ACCEPT') {
      return 'These terms have already been accepted';
    }
    return null;
  }

  @computed
  get draft() {
    return this.status === DRAFT;
  }

  @computed
  get readyToSubmit() {
    return this.kindItem.status === 'READY_TO_SUBMIT';
  }

  @computed
  get archived() {
    return Boolean(this.kindItem.archived);
  }

  @computed
  get active() {
    return !this.draft && !this.archived;
  }

  @computed
  get favorite() {
    return Boolean(this.kindItem.favorite);
  }

  @computed
  get pending() {
    return (
      this.active &&
      Boolean([SUBMITTED, PENDING].includes(this.kindItem.status))
    );
  }

  @computed
  get voided() {
    return Boolean(this.status === VOIDED);
  }

  @computed
  get inactive() {
    return this.archived || this.voided;
  }

  @computed
  get accepted() {
    return !this.inactive && this.status === ACCEPTED;
  }

  @computed
  get sortIndex() {
    return this.kindItem.sortIndex;
  }

  @computed
  get side() {
    return this.transaction.side;
  }

  @computed
  get submittedFromBuyerSide() {
    return Boolean(this.kindItem.submittedFromBuyerSide);
  }

  @computed
  get isPurchase() {
    return this.side === 'PURCHASE';
  }

  @computed
  get isSale() {
    return this.side === 'SALE';
  }

  @override
  get title() {
    if (this.isSale && this.primaryBuyerAgent) {
      return getFullNameOrEmail(this.primaryBuyerAgent) || 'Offer';
    }

    if (this.isPurchase && this.package.propertyInfo) {
      const propertyInfo = this.package.propertyInfo;
      if (propertyInfo?.address) {
        return getAddressLabel(propertyInfo.address);
      }
    }

    return 'Offer';
  }

  @computed
  get buyers() {
    return this.offerData?.buyers || [];
  }

  @computed
  get buyerAgents() {
    return this.offerData?.buyerAgents || [];
  }

  @computed
  get primaryBuyerAgent() {
    return this.buyerAgents[0];
  }

  @computed
  get sellers() {
    return this.offerData?.sellers || [];
  }

  @computed
  get listingAgents() {
    return this.offerData?.listingAgents || [];
  }

  @computed
  get primaryListingAgent() {
    return this.listingAgents[0];
  }

  @computed
  get otherSidePrimaryAgent() {
    return this.isPurchase ? this.primaryListingAgent : this.primaryBuyerAgent;
  }

  @computed
  get price() {
    return new Amount(currencyToNumber(this.offerData?.purchasePrice), null, {
      amountPrecision: 0,
    });
  }

  @computed
  get financingInfo() {
    return this.offerData?.financingInfo;
  }

  @computed
  get initialDeposit() {
    return new Amount(
      currencyToNumber(this.financingInfo?.initialDeposit),
      this.price.amount
    );
  }

  @computed
  get increasedDeposit() {
    return new Amount(
      currencyToNumber(this.financingInfo?.increasedDeposit),
      this.price.amount
    );
  }

  @computed
  get financing() {
    return (this.financingInfo?.financing || []).map((f) => ({
      ...f,
      typeData: f[camelCase(f.type) as keyof typeof f],
    }));
  }

  get orderedKeyTerms() {
    return this.kindItem.keyTerms || [];
  }

  get orderedVisibleKeyTerms() {
    return (this.kindItem.keyTerms || []).filter((kt) => kt.isVisible);
  }

  isVisible(term: string) {
    return (this.kindItem.keyTerms || []).filter(
      (kt) => kt.kind === term && kt.isVisible
    ).length;
  }

  @computed
  get financingSummary() {
    const financingSummary = this.financing.reduce(
      (summary, { type, loan }) => ({
        ...summary,
        type: summary.type || type,
        loanTypes: summary.loanTypes.concat(
          loan && !summary.loanTypes.includes(loan.type) ? [loan.type] : []
        ),
      }),
      {
        type: undefined,
        loanTypes: [],
      } as any
    );

    financingSummary.userType =
      FINANCING_TYPES[financingSummary.type as keyof typeof FINANCING_TYPES] ||
      financingSummary.type;
    financingSummary.userLoanTypes = financingSummary.loanTypes.map(
      (lt: string) => LOAN_TYPES[lt as keyof typeof LOAN_TYPES] || lt
    );

    return financingSummary;
  }

  @computed
  get totalInitialDeposit() {
    return new Amount(
      this.initialDeposit.amount || 0.0 + this.increasedDeposit.amount! || 0.0,
      this.price.amount
    );
  }

  @computed
  get downPayment() {
    return new Amount(
      this.price.amount
        ? Math.max(this.price.amount - (this.loanAmount.amount || 0.0), 0)
        : undefined,
      this.price.amount
    );
  }

  @computed
  get downPaymentBalance() {
    return new Amount(
      this.downPayment.amount
        ? Math.max(
            this.downPayment.amount - (this.totalInitialDeposit.amount || 0.0),
            0
          )
        : undefined,
      this.price.amount
    );
  }

  @computed
  get loanAmount() {
    let loanAmount;
    const loans = this.financing.filter(
      (f) => f.type === 'LOAN' && !isNil(f.loan?.amount)
    );

    if (loans.length) {
      loanAmount = loans.reduce((total, { loan }) => {
        const amount = loan?.amount;
        return total + (amount ? currencyToNumber(amount) : 0.0)!;
      }, 0.0);
    }

    return new Amount(loanAmount, this.price.amount);
  }

  @computed
  get loanDownAmount() {
    let loanDownAmount;
    if (this.price.isSet || this.loanAmount.isSet) {
      loanDownAmount = this.price.amount! - this.loanAmount.amount!;
    }

    return new Amount(loanDownAmount, this.price.amount);
  }

  @computed
  get contingencyTypes() {
    return (
      CONTINGENCIES_BY_STATE[
        this.reState as keyof typeof CONTINGENCIES_BY_STATE
      ] || CONTINGENCIES_BY_STATE.__default
    ).map((contingecyType, index) => ({
      ...contingecyType,
      index,
    }));
  }

  @computed
  get contingencyTypesMap() {
    return keyBy(this.contingencyTypes, 'key');
  }

  @computed
  get contingencyTerms() {
    return this.contingencyTypes.map(
      (c) => CONTINGENCY_TERMS[c.key as keyof typeof CONTINGENCY_TERMS]
    );
  }

  @computed
  get contingencies() {
    const contingencyTypesMap = this.contingencyTypesMap;
    const contingencies = sortBy(
      (this.offerData?.contingencies || []).filter(
        (c) => c.type in contingencyTypesMap
      ),
      (c) => contingencyTypesMap[c.type].index
    );
    return orderBy(contingencies || [], ['waived'], ['asc']);
  }

  @computed
  get publicNotes() {
    return this.kindItem.publicNotes;
  }

  @computed
  get privateNotes() {
    return this.kindItem.privateNotes;
  }

  @computed
  get notesFromOtherSide() {
    return this.kindItem.notesFromOtherSide;
  }

  getRelativeDate(ts?: number, days?: number, options?: any) {
    return new RelativeDate(ts, days, {
      tzName: this.tzName,
      tzSuffix: this.tzSuffix,
      ...options,
    });
  }

  @computed
  get submitted() {
    return this.getRelativeDate(this.kindItem.submittedTs);
  }

  @computed
  get closeOfEscrow() {
    return this.getRelativeDate(
      Number(this.offerData?.closeOfEscrowFixedTs),
      Number(this.offerData?.closeOfEscrowDaysFromAcceptance),
      {
        tzSuffix: null,
      }
    );
  }

  @computed
  get otherTerms() {
    return this.offerData?.otherTerms;
  }

  @computed
  get expiration() {
    return this.getRelativeDate(
      Number(this.otherTerms?.expirationFixedTs),
      Number(this.otherTerms?.expirationDaysFromSignature)
    );
  }

  @computed
  get expirationWithoutTz() {
    return this.getRelativeDate(
      Number(this.otherTerms?.expirationFixedTs),
      Number(this.otherTerms?.expirationDaysFromSignature),
      {
        tzSuffix: null,
      }
    );
  }

  getContingency(contigencyType: string) {
    return this.contingencies.find((c) => c.type === contigencyType);
  }

  isExpired() {
    const date = this.expiration.dateMoment;
    return Boolean(date && date < moment());
  }

  getComparator(term = 'default') {
    return [
      TERM_COMPARATORS[term as keyof typeof TERM_COMPARATORS],
      TERM_COMPARATORS.default,
    ].filter(Boolean)[0];
  }

  getDiffTerms(prevTerm: string, offerTerm: string, term: string) {
    const comparator = this.getComparator(term);
    return comparator(prevTerm, offerTerm)
      ? [offerTerm]
      : [prevTerm, offerTerm];
  }

  diffOfferTerm(prevOffer: Offer | undefined, term: string) {
    const offerTerm = get(this, term);
    const prevTerm = get(prevOffer || {}, term);

    if (!prevOffer) {
      return [offerTerm];
    }

    return this.getDiffTerms(prevTerm, offerTerm, term);
  }

  @computed
  get counter() {
    return this.offerData?.counter;
  }

  @computed
  get currentCounterTerms() {
    return this.counter?.terms || '';
  }

  @computed
  get historicCounterTerms() {
    return (this.counter?.termsHistory || [])
      .map((term: string) => {
        const parts = term.split(':');

        let side = parts[0];
        if (side === 'PURCHASE') {
          side = 'Buyer';
        } else if (side === 'SALE') {
          side = 'Seller';
        }

        const ts = parts[1];

        return {
          isOwnSide: parts[0] === this.transaction.side,
          side,
          ts,
          formattedTs: formatTimestamp(ts),
          terms: parts.slice(2).join(':'),
        };
      })
      .sort((t1, t2) => (+t1.ts < +t2.ts ? 1 : -1));
  }

  @computed
  get summaryTerms() {
    return (
      SUMMARY_TERMS_BY_STATE[
        this.reState as keyof typeof SUMMARY_TERMS_BY_STATE
      ] || SUMMARY_TERMS_BY_STATE.__default
    );
  }

  @computed
  get additionalFinancingTerms() {
    return this.offerData?.financingInfo?.additionalFinancingTerms ?? null;
  }

  @computed
  get otherKeyTerms() {
    return this.otherTerms?.otherTerms ?? null;
  }
}
