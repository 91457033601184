import filesize from 'filesize';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import pick from 'lodash/pick';
import reverse from 'lodash/reverse';
import { computed, makeObservable, override } from 'mobx';
import { TD_KIND_COVER_SHEET } from 'src/constants';
import Namespace from 'src/models/fields/namespace';
import Item, {
  ItemStore,
  TransactionItemJson,
} from 'src/models/transactions/items/item';
import type { AppStore } from 'src/stores/app-store';
import { FORM_NAMESPACE } from 'src/stores/pdf-annotations-store';
import { noExtension } from 'src/utils/filenames';
import type TransactionDocumentVersion from './transaction-document-version';

export const TD_HAS_TDV = 'TD_HAS_TDV';

export const FOLDER_HAS_DOCUMENT = 'FOLDER_HAS_DOCUMENT';

export const FORM_TYPE_STANDARD = 'STANDARD';

export const FORM_TYPE_CUSTOM = 'CUSTOM';

export const ALERT_KIND_ENTITLEMENT = 'ENTITLEMENT';

export const ALERT_KIND_INAPPLICABLE = 'INAPPLICABLE';

export const ALERT_KIND_MISSING_FIELDS = 'MISSING_FIELDS';

export const ALERT_KIND_DEACTIVATED_FORM = 'DEACTIVATED_FORMS';

export const FORM_TYPE_LABELS = {
  [FORM_TYPE_STANDARD]: 'Standard',
  [FORM_TYPE_CUSTOM]: 'Custom',
};

export const TD_SEEN_STATUSES = {
  TD_SEEN_STATUSES: 'IMPORTED_FROM_EMAIL',
  SEEN: 'SEEN',
  CHANGED: 'CHANGED',
  NOT_SEEN: 'NOT_SEEN',
  COMPLIANCE: 'COMPLIANCE',
};

const ANALYSIS_TIMEOUT_SECONDS = 120;

const PAGE_OCR_STATE = 3;

const SIGNATURE_ANALYSIS_CUTOFF =
  window.Glide.CONSTANTS.SIGNATURE_ANALYSIS_CUTOFF ?? 0.85;

export function getDefaultTdFetchOptions(appStore: AppStore) {
  // Glide embedded session loads all documents in the same page
  return {
    transactionDocumentFilter: !appStore.embeddedApp.isEmbedded
      ? 'not_trash'
      : undefined,
  };
}

class TdFormDataSource {
  namespace = FORM_NAMESPACE;
  public td: TransactionDocument;

  constructor(td: TransactionDocument) {
    this.td = td;
  }

  getFieldValue(fieldId: string) {
    if (!this.td.latestVersion || !this.td.latestVersion.isFillable) {
      return undefined;
    }

    return (
      this.td.latestVersion.fieldValues &&
      this.td.latestVersion.fieldValues[fieldId]
    );
  }

  getFieldIsUnlinked(fieldId: string) {
    if (!this.td.latestVersion || !this.td.latestVersion.isFillable) {
      return false;
    }
    return (
      !!this.td.latestVersion.unlinkedFieldIds &&
      this.td.latestVersion.unlinkedFieldIds.includes(fieldId)
    );
  }
}

export type TransactionDocumentJson =
  TransactionItemJson<'TRANSACTION_DOCUMENT'>;

export default class TransactionDocument extends Item<'TRANSACTION_DOCUMENT'> {
  resolvedItems = ['latestVersion'];

  constructor(store: ItemStore, json: TransactionDocumentJson) {
    super(store, json);

    makeObservable(this);
  }

  @computed
  get document() {
    return this.latestVersion ? this.latestVersion.document : null;
  }

  @computed
  get name() {
    return this.title;
  }

  @computed
  get formNamespace() {
    if (!this.latestVersion || !this.latestVersion.isFillable) {
      return null;
    }

    return new Namespace(new TdFormDataSource(this));
  }

  @computed
  get url() {
    if (!this.latestVersion) {
      return null;
    }
    return this.latestVersion.url || this.latestVersion.document;
  }

  get emitDict() {
    return {
      td_id: this.id,
      tdv_id: this.latestVersionId,
      form_id: this.formId,
      form_series_id: this.formSeriesId,
    };
  }

  @computed
  get tdKind() {
    return this.kindItem.kind;
  }

  @computed
  get isDraggable() {
    return !!this.can('drag');
  }

  @computed
  get isCoverSheet() {
    return this.tdKind === TD_KIND_COVER_SHEET;
  }

  @computed
  get isAutotabbable() {
    return !this.isCoverSheet;
  }

  @computed
  get version() {
    return this.kindItem.version;
  }

  @computed
  get latestVersionId() {
    return this.kindItem.latestVersionId;
  }

  @computed
  get index() {
    return this.kindItem.index;
  }

  @computed
  get latestVersion(): TransactionDocumentVersion | null {
    return this.latestVersionId
      ? this.store.itemsById.get(this.latestVersionId)
      : null;
  }

  @override
  get updatedAtNumber() {
    const num = this.kindItem.updatedAt || this.latestVersion?.effectiveFrom;
    return Number(num ?? 0);
  }

  @computed
  get archived() {
    return this.kindItem.archived;
  }

  @computed
  get pendingPackageAction() {
    return (this.inEdges || []).some(
      (e) => e.kind === 'PACKAGE_PENDING_ACTION_HAS_TD'
    );
  }

  @computed
  get versions() {
    return (this.outEdges || [])
      .filter((e) => e.kind === TD_HAS_TDV)
      .map((e) => this.store.itemsById.get(e.item2Id))
      .filter((foundItem) => foundItem);
  }

  @computed
  get versionsDesc() {
    return this.versions.sort((a, b) => {
      return b.version - a.version;
    });
  }

  @computed
  get inFolder() {
    return !!this.folderId;
  }

  @computed
  get folderId() {
    const folderEdge = this.inEdges.find((e) => e.kind === FOLDER_HAS_DOCUMENT);
    return folderEdge && folderEdge.item1Id;
  }

  @computed
  get fullPath() {
    const folder = this.store.itemsById.get(this.folderId);
    if (!folder) {
      return this.title;
    }
    return `${folder.title}/${this.title}`;
  }

  @computed
  get folder() {
    return this.store.itemsById.get(this.folderId);
  }

  @computed
  get isInTrash() {
    return this.folder ? this.folder.isTrash : false;
  }

  @computed
  get globalIndex() {
    // order by folder then by index
    const documentIndex = this.isCoverSheet ? -1 : this.index;
    const folderIndex = this.folder ? this.folder.orderIndex : -1;
    return folderIndex * 10 ** 9 + documentIndex;
  }

  @computed
  get folderTitle() {
    const { folder } = this;
    return folder ? folder.title : '';
  }

  @computed
  get formType() {
    return this.kindItem.formId ? FORM_TYPE_STANDARD : FORM_TYPE_CUSTOM;
  }

  @computed
  get notes() {
    return this.data.transactionDocument.notes;
  }

  // This is the status of the signature request to which the TD was attached to
  @computed
  get signatureStatus() {
    return this.kindItem.signatureStatus;
  }

  // A TD is signed if a signature request created it. We can tell by
  // checking inward edges
  @computed
  get signed() {
    return Boolean(
      this.inEdges.find(({ kind }) => kind === 'SIGNATURE_REQUEST_GENERATED_TD')
    );
  }

  @computed
  get tasksIds() {
    return this.inEdges
      .filter((e) => e.kind === 'TASK_ASSIGNED_TD')
      .map((e) => e.item1Id);
  }

  @computed
  get tasks() {
    return this.inEdges
      .filter((e) => e.kind === 'TASK_ASSIGNED_TD')
      .map((e) => this.store.itemsById.get(e.item1Id))
      .filter((task) => task);
  }

  @computed
  get checklistItems() {
    return this.tasks.filter((t) => t.type === 'CHECKLIST_ITEM');
  }

  @computed
  get tasksDesc() {
    return reverse(this.tasks.slice());
  }

  @computed
  get fileInfo() {
    const { pageCount } = this;
    const sizeDisplay = this.document.byteSize
      ? filesize(this.document.byteSize, {
          round: 1,
          base: 10,
        })
      : '?';
    if (isNumber(pageCount)) {
      if (pageCount === 1) {
        return `1 page - ${sizeDisplay}`;
      }
      return `${pageCount} pages - ${sizeDisplay}`;
    }

    return `${sizeDisplay}`;
  }

  @computed
  get titleNoExtension() {
    return noExtension(this.title);
  }

  @computed
  get displayName() {
    return this.titleNoExtension;
  }

  @computed
  get statusLabel() {
    if (this.signatureStatus === 'SIGNATURE_PENDING') {
      return {
        level: 'INFO',
        text: 'Signature Pending',
      };
    }

    return null;
  }

  @computed
  get isManualEditUnlocked() {
    return this.kindItem.isManualEditUnlocked;
  }

  /** Analysis Section * */
  @computed
  get isAnalyzing() {
    return this.latestVersion.isAnalyzing;
  }

  @computed
  get analyzedTime() {
    const stateByPart = get(this, 'document.analysis.stateByPart');
    const res =
      (stateByPart &&
        stateByPart[PAGE_OCR_STATE] &&
        stateByPart[PAGE_OCR_STATE].finishedAt) ||
      0;
    return res;
  }

  @computed
  get analysis() {
    return this.document?.analysis;
  }

  @computed
  get pageCount() {
    return this.analysis?.documentResult?.pageCount;
  }

  @computed
  get pageCountPluralized() {
    if (!this.pageCount) {
      return '';
    }

    return `${this.pageCount} page${this.pageCount !== 1 ? 's' : ''}`;
  }

  @computed
  get missingSignatures() {
    const analysisResult = this.analysis?.signatures?.result;

    if (analysisResult) {
      return analysisResult
        .filter(
          (r) =>
            r.clazz === 'SIGNATURE' &&
            r.userFeedback !== 'NOT_REQUIRED' &&
            r.score < SIGNATURE_ANALYSIS_CUTOFF
        )
        .sort((s1, s2) => {
          if (s1.zone.page !== s2.zone.page) {
            return s1.zone.page - s2.zone.page;
          }

          // keep them sorted left to right, top to bottom.
          // `top` has a small tolerance in case the manually placed annotations are
          // off by a couple of pixels, in which case we still want to consider
          // them on the same row
          return Math.abs(s1.zone.vertices[0].y - s2.zone.vertices[0].y) < 5
            ? s1.zone.vertices[0].x - s2.zone.vertices[0].x
            : s1.zone.vertices[0].y - s2.zone.vertices[0].y;
        });
    }

    return [];
  }

  @computed
  get analyzedSignatureInfo() {
    const info = {
      total_zones: 0,
      detected: 0,
    };

    const analysisResult = this.analysis?.signatures?.result;
    if (analysisResult) {
      info.total_zones = analysisResult.length;
      info.detected = analysisResult.length - this.missingSignatures.length;
    }

    return info;
  }

  isAnalysisFailed = (now) => {
    // warning: this is partial based on time so mobx
    // observation wont be suffiencent to update this
    const stateByPart = get(this, 'document.analysis.stateByPart');
    const isFailed =
      stateByPart &&
      stateByPart[PAGE_OCR_STATE] &&
      (stateByPart[PAGE_OCR_STATE].status === 'FAILED' ||
        stateByPart[PAGE_OCR_STATE].status === 'FAILED_REANALYSIS');
    if (isFailed) {
      return true;
    }
    const isPending =
      stateByPart &&
      stateByPart[PAGE_OCR_STATE] &&
      stateByPart[PAGE_OCR_STATE].status === 'PENDING';
    if (isPending) {
      const pendingTime = stateByPart[PAGE_OCR_STATE].attemptedAt;
      return now - pendingTime > 1000 * ANALYSIS_TIMEOUT_SECONDS;
    }

    return false;
  };
  /** END Analysis Section * */

  @computed
  get usersSeen() {
    return this.kindItem.usersSeen || [];
  }

  getSeenStatus(user) {
    const userSeen = this.usersSeen.find((us) => us.userId === user.id || user);
    if (this._isCompliance()) {
      return TD_SEEN_STATUSES.COMPLIANCE;
    }
    if (this._hasImportedFromEmail(userSeen)) {
      return TD_SEEN_STATUSES.IMPORTED_FROM_EMAIL;
    }
    if (this._hasUserNotSeenYet(userSeen)) {
      return TD_SEEN_STATUSES.NOT_SEEN;
    }
    if (this._hasVersionChanged(userSeen)) {
      return TD_SEEN_STATUSES.CHANGED;
    }
    return TD_SEEN_STATUSES.SEEN;
  }

  _isCompliance() {
    return this.kindItem.origin === 'COMPLIANCE';
  }
  _isTransactionEmail() {
    return this.kindItem.origin === 'TRANSACTION_EMAIL';
  }
  _hasUserNotSeenYet(userSeen) {
    return !userSeen;
  }

  _hasImportedFromEmail(userSeen) {
    return this._hasUserNotSeenYet(userSeen) && this._isTransactionEmail();
  }

  _hasVersionChanged(userSeen) {
    return userSeen?.versionId !== this.latestVersionId;
  }

  @computed
  get isFillable() {
    return this.latestVersion ? this.latestVersion.isFillable : undefined;
  }

  @computed
  get formId() {
    return this.latestVersion ? this.latestVersion.formId : undefined;
  }

  @computed
  get formSeriesId() {
    return this.latestVersion ? this.latestVersion.formSeriesId : undefined;
  }

  @computed
  get missingFillConditionsCount() {
    if (!this.transaction?.features?.missingFillConditions) {
      return undefined;
    }
    return this.latestVersion
      ? this.latestVersion.missingFillConditionsCount
      : undefined;
  }

  get inApplicableToProperty() {
    const { store: transactions } = this;

    if (!this.transaction || this.transaction.isTemplate) {
      return undefined;
    }

    if (!this?.latestVersion?.form?.seriesId) {
      return undefined;
    }

    if (!this.folder.isFromProperty) {
      return undefined;
    }

    const transactionForms = transactions.getFetchTransactionForms.get({
      transactionId: this.transactionId,
    });

    if (!transactionForms) {
      return undefined;
    }

    const { formByFormSeriesId } = transactionForms;
    if (!formByFormSeriesId) {
      return undefined;
    }

    const tdvForm = this.latestVersion.form;
    const transactionForm = formByFormSeriesId.get(tdvForm.seriesId);
    if (!transactionForm?.isPropertyDoc) {
      return undefined;
    }

    if (
      transactionForm.canApplyTo?.length !== 0 &&
      transactionForm.canApplyTo?.includes(this.folder.fromPropertyId)
    ) {
      return undefined;
    }
    return 'This document does not apply to this property address. Exercise caution in using this for your offer package.';
  }

  @computed
  get namespaceScopeEdgeKinds() {
    return {
      transactionPackageEdgeKind: 'PACKAGE_HAS_TD',
      propertyInfoEdgeKind: 'PROPERTY_HAS_TD',
    };
  }

  @computed
  get originalTdEdge() {
    return this.outEdges.find((e) => e.kind === 'TD_HAS_ORIGINAL_TD');
  }

  @computed
  get originalTdId() {
    return this.originalTdEdge?.item2Id || null;
  }

  @computed
  get canRestoreOriginal() {
    return Boolean(this.originalTdId && !this.isInTrash);
  }

  @computed
  get originalFillableTdId() {
    return this.originalTdEdge?.tdHasOriginalTd?.isFillable
      ? this.originalTdEdge.item2Id
      : null;
  }

  @computed
  get canRestoreFillable() {
    return Boolean(
      this.canRestoreOriginal && this.originalFillableTdId && !this.isFillable
    );
  }

  @computed
  get formOutlineFlowId() {
    return this.latestVersion?.formOutlineFlowId;
  }

  @computed
  get entitlementErrorMessage() {
    const { store: transactions } = this;
    const transactionForms = transactions.getFetchTransactionForms.get({
      transactionId: this.transactionId,
    });
    const { features } = transactions.parent;

    if (
      !this.transaction ||
      (this.transaction.isTemplate && !features.membershipVerificationEnabled)
    ) {
      return undefined;
    }

    if (!transactionForms) {
      return undefined;
    }
    if (!this?.latestVersion?.form?.seriesId) {
      return undefined;
    }

    const { formByFormSeriesId } = transactionForms;

    const tdvForm = this.latestVersion.form;
    const transactionForm = formByFormSeriesId.get(tdvForm.seriesId);

    if (transactionForm?.entitled) {
      return undefined;
    }

    if (this.isDeactivatedForm) {
      return undefined;
    }

    return 'Permission or membership verification needed to generate this form';
  }

  @computed
  get isDeactivatedForm() {
    return this.data.transactionDocument.isDeactivatedForm;
  }

  @computed
  get deactivatedFormMessage() {
    return 'This form has been deactivated and will not be copied. Please delete the form from this template';
  }

  @computed
  get alertMessages() {
    const { features, router } = this.store.parent;
    /* Shown in the TdAlertIndicator */
    const messages = [];
    if (this.missingFillConditionsCount) {
      messages.push({
        kind: ALERT_KIND_MISSING_FIELDS,
        text: `This form has ${this.missingFillConditionsCount} missing field${
          this.missingFillConditionsCount === 1 ? '' : 's'
        }`,
      });
    }
    if (this.entitlementErrorMessage) {
      let action;
      if (features.membershipVerificationEnabled) {
        action = {
          text: 'Review now',
          routeName: 'account.integrations',
          routeParams: {
            back: JSON.stringify(pick(router.hotRoute, ['name', 'params'])),
          },
        };
      }
      messages.push({
        kind: ALERT_KIND_ENTITLEMENT,
        text: this.entitlementErrorMessage,
        action,
      });
    }
    if (this.inApplicableToProperty) {
      messages.push({
        kind: ALERT_KIND_INAPPLICABLE,
        text: this.inApplicableToProperty,
      });
    }
    if (this.isDeactivatedForm) {
      messages.push({
        kind: ALERT_KIND_DEACTIVATED_FORM,
        text: this.deactivatedFormMessage,
      });
    }

    return messages;
  }

  @computed
  get isPropertyCreated() {
    return this.inEdges.some((e) => e.kind === 'PROPERTY_CREATED_TD');
  }

  @computed
  get isFromTransactionPackage() {
    return this.inEdges.some((e) => e.kind === 'PACKAGE_HAS_TD');
  }

  @computed
  get isLocked() {
    return this.isFromTransactionPackage && !this.pendingPackageAction;
  }

  get requiresServerSideRendering() {
    return this.isFillable || !!this.latestVersion.pdfAnnotateConfigId;
  }
}
