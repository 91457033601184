// import { computed, observable, map, action, runInAction } from 'mobx';
import AccountStore from './account-store';
import AuditStore from './audit-store';
import ActivityStore from './activity-store';
import ChecklistsStore from './checklists-store';
import ChoreStore from './chore-store';
import ClausesStore from './clauses-store';
import CommsStore from './comms-store';
import ContactStore from './contact-store';
import DocumentMissingSignatureStore from './document-missing-signatures-store';
import DocumentsStore from './documents-store';
import DocumentsUploadStore from './documents-upload-store';
import EmbeddedAppStore from './embedded-app-store';
import EnvelopeStore from './envelope-store';
import FeaturesStore from './features-store';
import FillConfigStore from './fill-config-store';
import FlowsStore from './flows-store';
import FormStore from './form-store';
import GuidedFormPacketsStore from './guided-form-packets-store';
import IntegrationStore from './integration-store';
import LayoutStore from './layout-store';
import OrgsStore from './orgs-store';
import PDFAnnotationsStore from './pdf-annotations-store';
import PackagesStore from './packages-store';
import PacketStore from './packet-store';
import PdfEsignStore from './pdf-esign-store';
import RouterStore from './router-store';
import SharedPacketsStore from './shared-packets-store';
import SignaturePacketsStore from './signature-packets-store';
import TasksStore from './tasks-store';
import TemplateChecklistsStore from './template-checklists-store';
import TransactionDocumentsStore from './transaction-documents-store';
import TransactionFieldsStore from './transaction-fields-store';
import TransactionFormsStore from './transaction-forms-store';
import TransactionStore from './transaction-store';
import TransactionTemplateStore from './transaction-template-store';
import TransactionUsersStore from './transaction-users-store';
import TransactionsListPageStore from './transaction-list-page-store';
import UIStore from './ui-store';
import ZipformTransactionsStore from './zipform-transactions-store';
import ZipformUploadStore from './zipform-upload-store';
import publicApi from 'src/api';
import routes from '../entries/app/routes';
import { FormBuilderStore } from './form-builder-store';
import { action, makeObservable, runInAction } from 'mobx';

import { getQueryParam } from 'src/utils/urls';

// TODO unify with web/auth/views.py::ONBOARDING_EXEMPT_PATH_RE
const ONBOARDING_EXEMPT_ROUTES_RE = /^(flow|account\.verifyMembership).*/;
const FETCH_SESSION_DATA_TIMEOUT = 10000;

export class AppStore {
  appName = 'agent';
  resolveInitialized = [];

  initialized: boolean;

  options: Window['Glide'];
  env: string;
  debug: boolean;

  api: typeof publicApi;

  ui: UIStore;
  router: RouterStore;
  account: AccountStore;
  audit: AuditStore;
  features: FeaturesStore;
  transactions: TransactionStore;
  transactionTemplates: TransactionTemplateStore;
  transactionUsers: TransactionUsersStore;
  transactionForms: TransactionFormsStore;

  templates: TransactionStore;
  activities: ActivityStore;
  packets: PacketStore;
  contacts: ContactStore;
  integrations: IntegrationStore;
  layouts: LayoutStore;
  flows: FlowsStore;
  documents: DocumentsStore;
  gfps: GuidedFormPacketsStore;
  transactionDocuments: TransactionDocumentsStore;
  transactionFields: TransactionFieldsStore;

  tasks: TasksStore;
  checklists: ChecklistsStore;
  sharedPackets: SharedPacketsStore;
  signaturePackets: SignaturePacketsStore;
  orgs: OrgsStore;
  annotations: PDFAnnotationsStore;
  uploads: DocumentsUploadStore;
  chores: ChoreStore;
  transactionsList: TransactionsListPageStore;
  zipformTransactions: ZipformTransactionsStore;
  zipformUpload: ZipformUploadStore;
  forms: FormStore;
  templateChecklists: TemplateChecklistsStore;
  fillConfigs: FillConfigStore;
  comms: CommsStore;
  clauses: ClausesStore;
  pdfEsign: PdfEsignStore;
  envelopes: EnvelopeStore;
  packages: PackagesStore;
  embeddedApp: EmbeddedAppStore;
  formBuilder: FormBuilderStore;
  missingSignatures: DocumentMissingSignatureStore;

  constructor({
    Router,
    Transactions,
    TransactionTemplates,
    TransactionUsers,
    TransactionForms,
    Packets,
    Contacts,
    Ui,
    Orgs,
    Account,
    Audit,
    Layouts,
    Features,
    Integrations,
    Documents,
    TransactionDocuments,
    TransactionFields,
    Tasks,
    Checklists,
    SharedPackets,
    GuidedFormPackets,
    Activities,
    Flows,
    PDFAnnotations,
    Uploads,
    Chores,
    TransactionsList,
    ZipformTransactions,
    ZipformUpload,
    Forms,
    TemplateChecklists,
    FillConfigs,
    Envelopes,
    Comms,
    Clauses,
    PdfEsign,
    Packages,
    EmbeddedApp,
    FormBuilder,
    DocumentMissingSignature,
    api,
  }) {
    makeObservable(this);

    this.api = api;
    this.ui = Ui && new Ui(this);
    this.router = Router && new Router(this, routes);
    this.account = Account && new Account(this);
    this.audit = Audit && new Audit(this);
    this.features = Features && new Features(this);
    this.transactions = Transactions && new Transactions(this);
    this.transactionTemplates =
      TransactionTemplates && new TransactionTemplates(this);
    this.transactionUsers = TransactionUsers && new TransactionUsers(this);
    this.transactionForms = TransactionForms && new TransactionForms(this);

    // XXX(hliu): We're using the TransactionStore for templates but in the future
    // we want to make it easier to grep this store dependency so that we can
    // swap it out.
    this.templates = this.transactions;
    this.activities = Activities && new Activities(this);
    this.packets = Packets && new Packets(this);
    this.contacts = Contacts && new Contacts(this);
    this.integrations = Integrations && new Integrations(this);
    this.layouts = Layouts && new Layouts(this);
    this.flows = Flows && new Flows(this);
    this.documents = Documents && new Documents(this);
    this.gfps = GuidedFormPackets && new GuidedFormPackets(this);
    this.transactionDocuments =
      TransactionDocuments && new TransactionDocuments(this);
    this.transactionFields = TransactionFields && new TransactionFields(this);

    this.tasks = Tasks && new Tasks(this);
    this.checklists = Checklists && new Checklists(this);
    this.sharedPackets = SharedPackets && new SharedPacketsStore(this);
    this.signaturePackets =
      SignaturePacketsStore && new SignaturePacketsStore(this);
    this.orgs = Orgs && new Orgs(this);
    this.annotations = PDFAnnotations && new PDFAnnotations(this);
    this.uploads = Uploads && new Uploads(this);
    this.chores = Chores && new Chores(this);
    this.transactionsList = TransactionsList && new TransactionsList(this);
    this.zipformTransactions =
      ZipformTransactions && new ZipformTransactions(this);
    this.zipformUpload = ZipformUpload && new ZipformUpload(this);
    this.forms = Forms && new Forms(this);
    this.templateChecklists =
      TemplateChecklists && new TemplateChecklists(this);
    this.fillConfigs = FillConfigs && new FillConfigs(this);
    this.comms = Comms && new Comms(this);
    this.clauses = Clauses && new Clauses(this);
    this.pdfEsign = PdfEsign && new PdfEsign(this);
    this.envelopes = Envelopes && new Envelopes(this);
    this.packages = Packages && new Packages(this);
    this.embeddedApp = EmbeddedApp && new EmbeddedApp(this);
    this.formBuilder = FormBuilder && new FormBuilder(this);
    this.missingSignatures =
      DocumentMissingSignature && new DocumentMissingSignature(this);
    this.initialized = false;
  }

  didUpdateRoute(route) {
    if (
      route.name &&
      !route.name.match(ONBOARDING_EXEMPT_ROUTES_RE) &&
      this.account.isAuthenticated &&
      (this.account.user.selectedRoles || []).filter((r) => r !== 'UNKNOWN')
        .length === 0 &&
      !this.account.isDemo &&
      !this.account.isInPerson &&
      !this.ui.isEmbedded
    ) {
      // Missing selected roles indicates that the user needs to be onboarded.
      // A hard refresh will accomplish that.
      location.reload();
    }
  }

  startSpinning() {
    // deprecated
    this.ui.startSpinning();
  }

  stopSpinning() {
    // deprecated
    this.ui.stopSpinning();
  }

  @action
  forceStopSpinning() {
    // deprecated
    this.ui.forceStopSpinning();
  }

  waitForBearerTokenToBeSet = () => {
    let r;
    const wait = new Promise((resolve) => {
      r = resolve;
    });
    const CHECK_INTERVAL = 50;
    const waitTimeout = () => {
      if (r && window.Glide?.authBearer) {
        r(window.Glide.authBearer);
      } else {
        setTimeout(waitTimeout, CHECK_INTERVAL);
      }
    };
    waitTimeout();
    return wait;
  };

  async hasToFetchSessionData(remove = true, timeout) {
    const fetchSessionPrerequisiteHandlers = {
      bearerToken: this.waitForBearerTokenToBeSet,
    };
    const res = getQueryParam(
      window.Glide.CONSTANTS.FETCH_SESSION_DATA_QPARAM,
      remove
    );
    if (fetchSessionPrerequisiteHandlers[res]) {
      await Promise.race([
        fetchSessionPrerequisiteHandlers[res](),
        ...[
          (timeout ?? 0) > 0 &&
            new Promise((_, reject) => {
              setTimeout(
                () => reject(new Error('Token bearer timeout.')),
                timeout
              );
            }),
        ].filter(Boolean),
      ]);
    }
    return Boolean(res);
  }

  setInitialized = () => {
    this.initialized = true;
    while (this.resolveInitialized.length > 0) {
      const r = this.resolveInitialized.splice(0, 1)[0];
      r(true);
    }
  };

  preInitialize() {
    // eslint-disable-next-line no-unused-expressions
    this.embeddedApp?.initialize();
  }

  initialize_() {
    runInAction(() => {
      this.account.initialize(this.options);
      this.transactions.initialize();
      this.transactionTemplates.initialize();
      this.transactionUsers.initialize();
      this.ui.initialize();
      this.orgs.initialize(this.options);
      this.activities.initialize();
      this.flows.initialize();
      this.features.initialize();
      this.uploads.initialize(this.options);
      this.tasks.initialize();
      this.transactionsList.initialize();
      this.zipformTransactions.initialize();
      this.zipformUpload.initialize();
      this.comms.initialize();
      this.envelopes.initialize();
      this.setInitialized();
    });
  }

  initialize = async () => {
    if (this.initialized) {
      return;
    }

    runInAction(() => {
      this.options = window.Glide;
      this.env = this.options.env;
      this.debug = this.options.debug;
    });

    this.preInitialize();
    if (await this.hasToFetchSessionData(true, FETCH_SESSION_DATA_TIMEOUT)) {
      const res = await publicApi.auth.fetchSession();
      const { redirectUrl, ...newData } = res.data ?? {};
      if (redirectUrl) {
        if (this.embeddedApp?.isEmbedded) {
          // TODO deal with absoulte redirect URLs and URLs from different entrypoints
          const backRoute = this.router.matchPath(
            window.location.pathname + (window.location.search || '')
          );
          const { name, params } = this.router.matchPath(redirectUrl);
          params.back = JSON.stringify({
            name: backRoute.name,
            params: backRoute.params,
          });
          this.router.setInterceptRedirectRoute({
            name,
            params,
          });
        } else {
          window.location.href = redirectUrl;
        }
      }
      window.setGlideContext(window.Glide, newData);
      window.setGtag();
    }
    this.initialize_();
  };

  isInitialized = () => {
    return this.initialized
      ? Promise.resolve(true)
      : new Promise((resolve) => {
          this.resolveInitialized.push(resolve);
        });
  };

  handleError() {
    alert('Oops! Something went wrong.'); // eslint-disable-line
  }

  handleNotFoundError() {
    this.handleFatalError("Couldn't find what you were looking for.");
  }

  handleFatalError(message) {
    if (message) {
      this.ui.toast({
        message,
        type: 'error',
      });
    } else {
      this.ui.wentWrong();
    }
    this.router.navigate('home');
  }

  logOut() {
    this.account.logOut();
  }
}

export const subStores = {
  Router: RouterStore,
  Transactions: TransactionStore,
  TransactionTemplates: TransactionTemplateStore,
  TransactionUsers: TransactionUsersStore,
  TransactionForms: TransactionFormsStore,
  Packets: PacketStore,
  Contacts: ContactStore,
  Ui: UIStore,
  Account: AccountStore,
  Layouts: LayoutStore,
  Integrations: IntegrationStore,
  Documents: DocumentsStore,
  DocumentMissingSignature: DocumentMissingSignatureStore,
  Features: FeaturesStore,
  TransactionDocuments: TransactionDocumentsStore,
  TransactionFields: TransactionFieldsStore,
  Tasks: TasksStore,
  Checklists: ChecklistsStore,
  SharedPackets: SharedPacketsStore,
  Activities: ActivityStore,
  GuidedFormPackets: GuidedFormPacketsStore,
  Orgs: OrgsStore,
  PDFAnnotations: PDFAnnotationsStore,
  Flows: FlowsStore,
  Uploads: DocumentsUploadStore,
  Chores: ChoreStore,
  TransactionsList: TransactionsListPageStore,
  ZipformTransactions: ZipformTransactionsStore,
  ZipformUpload: ZipformUploadStore,
  Forms: FormStore,
  TemplateChecklists: TemplateChecklistsStore,
  FillConfigs: FillConfigStore,
  Comms: CommsStore,
  Clauses: ClausesStore,
  PdfEsign: PdfEsignStore,
  Envelopes: EnvelopeStore,
  Packages: PackagesStore,
  EmbeddedApp: EmbeddedAppStore,
  FormBuilder: FormBuilderStore,
};

export default new AppStore({
  ...subStores,
  api: publicApi,
});
