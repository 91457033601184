import get from 'lodash/get';
import has from 'lodash/has';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import negate from 'lodash/negate';
import partial from 'lodash/partial';
import sortBy from 'lodash/sortBy';
import zip from 'lodash/zip';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { trackError } from 'src/analytics';
import type Activities from 'src/api/public/activities';
import logger from 'src/logger';
import type Party from 'src/models/transactions/items/party';
import type {
  Activity,
  ActivityKind,
  ActivityList,
} from 'src/types/proto/transactions';
import autorunPromise from 'src/utils/autorun-promise';
import { getFetch } from 'src/utils/get-fetch';
import type { AppStore } from './app-store';

const ACTIVITIES_LIMIT: number = window.Glide.CONSTANTS.ACTIVITIES_LIMIT;

type PartyOrNull = Party | { id: null };

interface OtherFilters {
  excludeSoftDeletedComments?: boolean;
}

interface MemoizeKeyParams {
  transactionId?: string;
  itemId?: string;
  flowId?: string;
  filterParties?: PartyOrNull[] | null;
  kinds?: ActivityKind[];
  offset?: number;
  limit?: number;
  otherFilters?: OtherFilters;
}

interface ActivitiesQuery
  extends Omit<MemoizeKeyParams, 'otherFilters'>,
    OtherFilters {
  itemIds?: string[];
}

interface LoadActivityPusherParams {
  ids: string[];
  transactionIds: string[];
  flowIds: string[];
}

type ActivityCallback = (activities: Activity[]) => void;

interface GetFetchItemsActivityQuery extends OtherFilters {
  transactionId: string;
  itemIds: string[];
  filterParties?: Party[];
  kinds?: ActivityKind[];
  limit?: number;
}

interface GetFetchActivitySummaryQuery {
  transactionId: string;
  itemIds?: string[];
  kinds?: ActivityKind[];
}

type ActivityByKind = Partial<Record<ActivityKind, Record<string, boolean>>>;

const arrayMemoizeKeyPart = (values?: string[]) =>
  (values || []).sort().join('_');
const filterPartiesMemoizeKeyPart = (parties?: PartyOrNull[] | null) =>
  arrayMemoizeKeyPart(
    (parties || []).map((p) => p.id).filter((id) => id) as string[]
  );

const otherFiltersMemoizeKeyPart = (filters?: OtherFilters) =>
  arrayMemoizeKeyPart(
    Object.entries(filters || {})
      .filter(([k, v]) => v)
      .map(([k, v]) => `${k}_${v}`)
  );

const getMemoizeKey = ({
  transactionId,
  itemId,
  flowId,
  filterParties,
  kinds,
  offset,
  limit = ACTIVITIES_LIMIT,
  otherFilters,
}: MemoizeKeyParams) =>
  `${transactionId}:${itemId}:${flowId}:${filterPartiesMemoizeKeyPart(
    filterParties
  )}:${arrayMemoizeKeyPart(kinds)}:${otherFiltersMemoizeKeyPart(
    otherFilters
  )}:${offset}:${limit}`;

export default class ActivityStore {
  // Since itemId, transactionId, and `flow:${flowId}` cannot conflict,
  // we can use them as keys in the same Map
  @observable activitiesByIndexKey = new Map<string, Activity[]>();
  @observable isQueryCachedByMemoizeKey = new Map<string, boolean>();
  @observable activitiesByItemId = new Map<string, ActivityByKind>();

  activitiesCallbacks: ActivityCallback[] = [];

  parent: AppStore;
  api: Activities;

  constructor(parent: AppStore) {
    makeObservable(this);
    this.parent = parent;
    this.api = this.parent.api.activities;
  }

  get ui() {
    return this.parent.ui;
  }

  get transactions() {
    return this.parent.transactions;
  }

  initialize = () => {
    if (this.account.isAuthenticated) {
      this.account.subscribeUserEvent(
        'loadActivities',
        ({ ids, transactionIds, flowIds }: LoadActivityPusherParams) => {
          logger.log('loadActivities', {
            ids,
          });
          let idsToFetch = ids;
          if (transactionIds && flowIds) {
            idsToFetch = zip(ids, transactionIds, flowIds)
              .filter(([, transactionId, flowId]) => {
                // fetch all activities for flows because in this
                // case you might see them without having
                // the transaction in the store
                return (
                  (transactionId &&
                    this.transactions.transactionsById.get(transactionId)) ||
                  flowId
                );
              })
              .map(([id]) => id) as string[];
          }

          if (!idsToFetch.length) {
            return;
          }
          this.fetchActivitiesMulti(ids, true);
        }
      );
    }
  };

  get account() {
    return this.parent.account;
  }

  isSeen = (userId: string, activity: Activity) => {
    return Boolean(
      activity.userId === userId ||
        activity.usersSeen.find((us) => us.userId === userId) ||
        get(activity, 'comment.softDeleted')
    );
  };

  /* Activity -------------------------------------------------- */

  @action
  updateActivities = (activities: Activity[]) => {
    const toSort: string[] = [];
    const added = activities.filter((activity) => {
      const indexKeys = [...(activity.itemIds || [])];
      if (activity.transId && !indexKeys.includes(activity.transId)) {
        indexKeys.push(activity.transId);
      }
      return [...indexKeys].filter((key) => {
        if (!this.activitiesByIndexKey.has(key)) {
          this.activitiesByIndexKey.set(key, []);
        }
        const existings = this.activitiesByIndexKey.get(key) as Activity[];
        const existing: Partial<Record<keyof Activity, any>> | undefined =
          existings.find((e) => e.id === activity.id);
        if (existing) {
          keys(existing).forEach((prop) => {
            if (!has(activity, prop)) {
              delete existing[prop as keyof Activity];
            }
          });
          keys(activity).forEach((prop) => {
            existing[prop as keyof Activity] = activity[prop as keyof Activity];
          });
          return false;
        }
        existings.push(observable(activity));
        toSort.push(key);
        return true;
      }).length;
    });
    toSort.forEach((key) => {
      const existings = this.activitiesByIndexKey.get(key);
      const sorted = sortBy(existings, (a) => -1 * +a.createdAt);
      this.activitiesByIndexKey.set(key, sorted);
    });

    // Update summary
    const userId = get(this.account, 'user.id');
    if (userId) {
      activities.forEach((activity) => {
        const { id: activityId, kind } = activity;
        const itemIds = activity.itemIds || [];

        itemIds.forEach((itemId) => {
          const data = this.activitiesByItemId.get(itemId) || {};
          const kindData = data[kind] || {};
          kindData[activityId] = this.isSeen(userId, activity);
          this.activitiesByItemId.set(itemId, {
            ...data,
            [kind]: kindData,
          });
        });

        // Remove this activity form, any item it is not longer related to.
        Array.from(this.activitiesByItemId.keys())
          .filter((itemId) => !itemIds.includes(itemId))
          .forEach((itemId) => {
            const data = this.activitiesByItemId.get(itemId) || {};
            const kindData = data[kind] || {};
            delete kindData[activityId];
            this.activitiesByItemId.set(itemId, {
              ...data,
              [kind]: kindData,
            });
          });
      });
    }

    return added;
  };

  fetchActivitiesMulti = async (ids: string[], events?: boolean) => {
    const { data } = await this.api.fetchMultiActivities(ids);
    const newActivities = this.updateActivities(data);
    if (events && newActivities.length) {
      this.activitiesCallbacks.forEach((f) => {
        try {
          f(newActivities);
        } catch (e) {
          trackError(e, 'Activities Callback Error');
        }
      });
    }
  };

  updateActivity = async (
    id: string,
    message: string,
    inviteSubject: string,
    inviteBody: string
  ) => {
    try {
      const { data } = await this.api.updateActivity(id, {
        value: message,
        inviteSubject,
        inviteBody,
      });
      this.updateActivities([data]);
    } catch (err) {
      this.ui.wentWrong(err);
    }
  };

  deleteActivity = async (id: string, deleted: boolean) => {
    try {
      const { data } = await this.api.deleteActivity(id, {
        value: deleted,
      });
      this.updateActivities([data]);
    } catch (err) {
      this.ui.wentWrong(err);
    }
  };

  hasClientIdPromise = ({
    itemId,
    transactionId,
    flowId,
    clientId,
  }: {
    itemId?: string;
    transactionId?: string;
    flowId?: string;
    clientId: string;
  }) => {
    return autorunPromise(() => {
      const activities =
        this.activitiesByIndexKey.get(
          itemId || transactionId || `flow:${flowId}`
        ) || [];
      return activities && activities.some((a) => a.clientId === clientId);
    });
  };

  isQueryCached = (query: ActivitiesQuery) => {
    return this.isQueryCachedByMemoizeKey.get(getMemoizeKey(query)) || false;
  };

  getCached = (indexKey: string, query: MemoizeKeyParams) => {
    const { itemId, filterParties, kinds, otherFilters } = query;

    let res = this.activitiesByIndexKey.get(indexKey) || [];

    if (itemId) {
      res = res.filter((a) => a.itemIds.includes(itemId));
    }

    if (filterParties && filterParties.length) {
      const partyIds = filterParties.map((p) => p.id);
      res = res.filter((a) => intersection(a.itemIds, partyIds).length > 0);
    }

    if (kinds && kinds.length) {
      res = res.filter((a) => kinds.includes(a.kind));
    }

    if (otherFilters && otherFilters.excludeSoftDeletedComments) {
      res = res.filter((a) => !get(a, 'comment.softDeleted'));
    }

    return res;
  };

  getActivities = (query: MemoizeKeyParams) => {
    const { transactionId, flowId, offset = 0, limit, itemId } = query;

    const indexKey = itemId || transactionId || `flow:${flowId}`;
    const res = this.getCached(indexKey, query);

    if (offset !== undefined) {
      return res.slice(offset, limit);
    }

    return res;
  };

  fetchActivities = async (query: MemoizeKeyParams) => {
    const {
      transactionId,
      flowId,
      offset = 0,
      limit = ACTIVITIES_LIMIT,
      itemId,
      filterParties,
      kinds,
    } = query;
    let data: ActivityList;

    if (flowId) {
      if (!isEmpty(filterParties)) {
        throw new Error(
          'Fetching activities for flow while filtering for parties not implemented.'
        );
      }

      ({ data } = await this.api.fetchActivityByFlow(flowId, {
        item: itemId || undefined,
        offset,
        limit,
      }));
    } else if (transactionId) {
      ({ data } = await this.api.fetchActivity(transactionId, {
        item: itemId || undefined,
        parties: (filterParties || [])
          .map((p) => p.id)
          .filter(Boolean) as string[],
        offset,
        limit,
        kinds,
      }));
    } else {
      throw new Error('Transaction or flow required to fetch activities.');
    }

    runInAction(() => {
      this.updateActivities(data.data);
      this.isQueryCachedByMemoizeKey.set(getMemoizeKey(query), true);
    });
    return data;
  };

  getItemsActivity = (query: GetFetchItemsActivityQuery) => {
    const { limit, itemIds } = query;

    let res: Activity[] = [];

    (itemIds || []).forEach((itemId) => {
      res = res.concat(
        this.getCached(itemId, {
          ...query,
          itemId,
        })
          .slice(0, limit)
          .filter((act) => !res.map((a) => a.id).includes(act.id))
      );
    });

    return res;
  };

  fetchItemsActivity = async (query: GetFetchItemsActivityQuery) => {
    const {
      transactionId,
      limit,
      itemIds,
      filterParties,
      kinds,
      ...otherFilters
    } = query;
    const { data } = await this.api.fetchItemsActivity(transactionId, {
      limit,
      items: itemIds,
      parties: filterParties?.map((p) => p.id),
      kinds,
      ...otherFilters,
    });
    runInAction(() => {
      this.updateActivities(data.data);
      itemIds.forEach((itemId) =>
        this.isQueryCachedByMemoizeKey.set(
          getMemoizeKey({
            ...query,
            itemId,
            offset: 0,
            limit,
            otherFilters,
          }),
          true
        )
      );
    });
    return data.data;
  };

  getFetchActivity = getFetch<MemoizeKeyParams, Activity[]>({
    bindTo: this,
    getMemoizeKey,
    getter: (val) => {
      return this.getActivities({
        ...val,
        // Preserves legacy getter behavior:
        offset: 0,
        limit: undefined,
      });
    },
    fetcher: (val) => {
      return this.fetchActivities(val);
    },
  });

  getFetchItemsActivity = getFetch<GetFetchItemsActivityQuery, Activity[]>({
    bindTo: this,
    getMemoizeKey,
    getter: (query) => this.getItemsActivity(query),
    fetcher: (query) => this.fetchItemsActivity(query),
  });

  getFetchActivitySummary = getFetch<
    GetFetchActivitySummaryQuery,
    Record<string, ActivityByKind>
  >({
    bindTo: this,
    getMemoizeKey: ({ itemIds, kinds }) =>
      [(kinds || []).sort().join(':'), (itemIds || []).sort().join(':')].join(
        ':'
      ),
    getter: ({ itemIds, kinds }) => {
      const res: Record<string, ActivityByKind> = {};
      (itemIds || []).forEach((itemId) => {
        const data = this.activitiesByItemId.get(itemId);
        if (data) {
          res[itemId] = {};
          Object.keys(data)
            .filter((k) => !kinds || kinds.includes(k as ActivityKind))
            .forEach((kind) => {
              res[itemId][kind as ActivityKind] =
                data[kind as ActivityKind] || {};
            });
        }
      });
      return res;
    },
    fetcher: async ({ transactionId, itemIds, kinds }) => {
      const {
        data: { entries },
      } = await this.api.fetchActivitySummary(transactionId, {
        items: itemIds,
        kinds,
      });
      const activitiesByItemId: Record<string, ActivityByKind> = (
        itemIds || []
      ).reduce(
        (all, itemId) => ({
          ...all,
          [itemId]: {},
        }),
        {}
      );
      entries.forEach(({ itemId, kind, activities }) => {
        activitiesByItemId[itemId][kind as ActivityKind] = activities.reduce(
          (all, { id, seen }) => ({
            ...all,
            [id]: Boolean(seen),
          }),
          {}
        );
      });
      runInAction(() => {
        Object.entries(activitiesByItemId).forEach(([itemId, value]) => {
          this.activitiesByItemId.set(itemId, value);
        });
      });
      return activitiesByItemId;
    },
  });

  getActivityIds = (
    itemId: string,
    kind: ActivityKind,
    seen?: boolean | null
  ) =>
    Object.entries((this.activitiesByItemId.get(itemId) || {})[kind] || {})
      .filter(
        ([_id, activitySeen]) =>
          seen === undefined || seen === null || activitySeen === seen
      )
      .map(([id]) => id);
  getTotalCount = (itemId: string, kind: ActivityKind) =>
    this.getActivityIds(itemId, kind).length;
  getSeenIds = (itemId: string, kind: ActivityKind) =>
    this.getActivityIds(itemId, kind, true);
  getSeenCount = (itemId: string, kind: ActivityKind) =>
    this.getSeenIds(itemId, kind).length;
  getUnseenIds = (itemId: string, kind: ActivityKind) =>
    this.getActivityIds(itemId, kind, false);
  getUnseenCount = (itemId: string, kind: ActivityKind) =>
    this.getUnseenIds(itemId, kind).length;

  setSeen = async (
    id: string,
    activities: [activity: Activity, seen: boolean][],
    byFlow?: boolean
  ) => {
    // TODO optimistic update
    const setSeenApiMethod = byFlow
      ? this.api.activitiesSetSeenByFlow.bind(this.api)
      : this.api.activitiesSetSeen.bind(this.api);
    const { data } = await setSeenApiMethod(id, {
      activities: activities.reduce(
        (all, [a, seen]) => ({
          ...all,
          [a.id]: Boolean(seen),
        }),
        {}
      ),
    });

    data.forEach((dataActivity) => {
      if (!activities || !activities[0] || !Array.isArray(activities[0])) {
        return;
      }
      const currentActivity = activities.find(
        ([activity]) => dataActivity.id === activity.id
      )?.[0];
      if (currentActivity?.log) {
        dataActivity.log = currentActivity.log;
      }
    });
    this.updateActivities(data);
    return data;
  };

  setAllUnseenAsSeen = async (seeActivities: Activity[]) => {
    const userId = get(this.account, 'user.id');
    const transactionId = (seeActivities.find((a) => a.transId) || {})
      .transId as string;
    const activities = seeActivities.filter((a) => a.transId === transactionId);
    if (userId && activities.length) {
      const unseenActivities = activities.filter(
        negate(partial(this.isSeen, userId))
      );
      if (unseenActivities.length) {
        return this.setSeen(
          transactionId,
          unseenActivities.map((a) => [a, true])
        );
      }
    }

    return [];
  };

  subscribeActivities = (f: ActivityCallback) =>
    this.activitiesCallbacks.push(f);
}
