

import ActionsRow from 'src/components/common/actions-row';
import AppButton from 'src/components/common/app-button';
import AppLoadingModal from 'src/components/common/app-loading-modal';
import EmailTemplates from 'src/components/templates/email-templates';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import SharePacketLinkForm from './share-packet-link-form';
import SharingLegal from 'src/components/common/sharing-legal';
import chaining from 'src/utils/chaining';
import classNames from 'classnames';
import getFullNameOrEmail from 'src/utils/get-full-name-or-email';
import getKindData from 'src/utils/get-kind-data';
import { Alert, Col, Input, Row, Select, Tabs } from 'antd';
import { DEFAULT_GUTTER } from 'src/constants';
import { Email, Required } from 'src/components/forms/quick-form/validation';
import { QuickForm } from 'src/components/forms/quick-form';
import { inject, observer } from 'mobx-react';
import { inviteToSharePacket } from 'src/models/transactions/intents';
import { makeNewContactRecipient } from 'src/models/transactions/recipients';

const clsPrefix = 'app-share-packet-invite-modal';

const SHARE_INVITE_TEMPLATE_FIELD = 'SHARE_INVITE_MESSAGE';

@inject('ui', 'account', 'sharedPackets')
export class BaseSharePacketInviteEmailModal extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    sharedPackets: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onInvite: PropTypes.func.isRequired,
    defaultRole: PropTypes.string,
    defaultTab: PropTypes.string,
    sharePacket: PropTypes.object,
    linkShare: PropTypes.bool,
    onOK: PropTypes.func,
    isReshare: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      tab: null,
    };
  }

  getTab() {
    return this.state.tab || this.props.defaultTab || 'email';
  }

  handleSaveInvite = (values) => {
    const { onInvite } = this.props;
    const [firstName, ...lastNames] = values.name.trim().split(' ');
    const lastName = lastNames.join(' ');
    const email = values.email;
    const recipients = [
      {
        ...makeNewContactRecipient({
          email,
          firstName,
          lastName,
        }),
        recipientRole: values.role,
      },
    ];
    const { message } = values;
    return onInvite({
      recipients,
      message,
    });
  };

  enableSharing = () => {
    const { sharedPackets, sharePacket, onClose } = this.props;
    sharedPackets.updateAccessStatus(sharePacket, 'ENABLED');
    onClose();
  };

  handleUseDefaultMessage = async (value, onChange) => {
    onChange({
      message: value,
    });
  };

  render() {
    const {
      onClose,
      linkShare,
      defaultRole,
      isReshare,
      onOK,
      visible,
      sharePacket,
      ui,
      account,
    } = this.props;
    let submitInviteFunc;
    const emailForm = (
      <Fragment>
        <QuickForm
          hideControls
          onSave={this.handleSaveInvite}
          initialValue={{
            name: '',
            role: defaultRole,
            email: '',
            message: '',
          }}
          validation={{
            role: Required({
              label: 'Role',
            }),
            name: Required({
              label: 'Name',
            }),
            email: [
              Required({
                label: 'Email',
              }),
              Email(),
            ],
          }}
          container="form"
        >
          {({ onChange, currentValues, submit }) => {
            submitInviteFunc = submit;
            return (
              <Fragment>
                {isReshare && currentValues.role === 'BUYER' && (
                  <Alert
                    message={
                      'Don’t worry, your buyer’s contact info won’t' +
                      ' be shared with the listing agent.'
                    }
                    type="info"
                  />
                )}
                <QuickForm.Item name="role" label="Role">
                  <Select size="large" optionLabelProp="title">
                    <Select.Option key="BUYER_AGENT" title="Buyer Agent">
                      <div>
                        <div className={`${clsPrefix}__role-primary`}>
                          Buyer Agent
                        </div>
                        <div className={`${clsPrefix}__role-explanation`}>
                          <div>
                            Can view and re-share the disclosure package.
                          </div>
                          {isReshare && (
                            <div>
                              Contact info will be shown to listing team.
                            </div>
                          )}
                        </div>
                      </div>
                    </Select.Option>
                    <Select.Option key="BUYER" title="Buyer">
                      <div>
                        <div className={`${clsPrefix}__role-primary`}>
                          Buyer
                        </div>
                        <div className={`${clsPrefix}__role-explanation`}>
                          <div>Cannot re-share the disclosure package.</div>
                          {isReshare && (
                            <div>
                              Contact info will not be shown to listing team.
                            </div>
                          )}
                        </div>
                      </div>
                    </Select.Option>
                  </Select>
                </QuickForm.Item>
                <Row gutter={DEFAULT_GUTTER}>
                  <Col sm={12} xs={24}>
                    <QuickForm.Item name="name" label="Name">
                      <Input />
                    </QuickForm.Item>
                  </Col>
                  <Col sm={12} xs={24}>
                    <QuickForm.Item name="email" label="Email">
                      <Input />
                    </QuickForm.Item>
                  </Col>
                </Row>
                <QuickForm.Item name="message">
                  <Input.TextArea placeholder="Add a personal message (Optional)" />
                </QuickForm.Item>
                {account.isAuthenticated && (
                  <ActionsRow
                    style={{
                      marginTop: -32,
                    }}
                    className="margin-bottom-sm"
                  >
                    <EmailTemplates
                      category={SHARE_INVITE_TEMPLATE_FIELD}
                      onTemplateSelect={(value) =>
                        this.handleUseDefaultMessage(value, onChange)
                      }
                      getMessage={() => currentValues.message}
                    />
                  </ActionsRow>
                )}
              </Fragment>
            );
          }}
        </QuickForm>
        <SharingLegal />
      </Fragment>
    );

    return (
      <AppLoadingModal
        className={classNames(clsPrefix, {
          [`${clsPrefix}--has-tabs`]: linkShare,
        })}
        title="Share Disclosure Package"
        visible={visible}
        onCancel={onClose}
        onOk={() => {
          if (this.getTab() === 'email') {
            return submitInviteFunc();
          }
          return chaining(onClose, onOK)();
        }}
        okButtonProps={{
          disabled: sharePacket && !sharePacket.isSharingEnabled,
        }}
        embeddedReducedOffset
      >
        {sharePacket &&
          sharePacket.folder &&
          !sharePacket.folder.documentCount && (
            <Alert
              className="margin-right margin-left margin-bottom-sm"
              type="warning"
              message={
                "Heads up, you haven't added any documents in your package yet."
              }
            />
          )}
        {sharePacket && !sharePacket.isSharingEnabled && (
          <Alert
            className="margin-right margin-left margin-bottom-sm"
            type="warning"
            message={
              <div className="flex-row-space-between">
                <span>
                  {ui.isMobileSize
                    ? 'Sharing is disabled'
                    : 'Disclosure Package Sharing is disabled'}
                </span>
                <AppButton type="primary" onClick={this.enableSharing}>
                  {ui.isMobileSize ? 'Enable' : 'Enable sharing'}
                </AppButton>
              </div>
            }
          />
        )}
        {linkShare ? (
          <Tabs
            type="card"
            className={`${clsPrefix}__tabs`}
            activeKey={this.getTab()}
            onChange={(tab) =>
              this.setState({
                tab,
              })
            }
          >
            <Tabs.TabPane
              className={`${clsPrefix}__tab-pane`}
              tab="By Email"
              key="email"
              type="card"
            >
              {emailForm}
            </Tabs.TabPane>
            <Tabs.TabPane
              className={`${clsPrefix}__tab-pane`}
              tab="By Link"
              key="link"
            >
              <SharePacketLinkForm sharePacket={sharePacket} />
            </Tabs.TabPane>
          </Tabs>
        ) : (
          emailForm
        )}
      </AppLoadingModal>
    );
  }
}

@inject('ui', 'transactions', 'sharedPackets')
@observer
export default class SharePacketInviteEmailModal extends Component {
  static propTypes = {
    ui: PropTypes.object.isRequired,
    transactions: PropTypes.object.isRequired,
    sharedPackets: PropTypes.object.isRequired,
    sharePacket: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onInvite: PropTypes.func,
  };

  static defaultProps = {
    onInvite: () => {},
  };

  handleSaveInvite = async ({ recipients, message }) => {
    const { ui, transactions, sharePacket, sharedPackets } = this.props;

    try {
      await transactions.dispatch(
        sharePacket.transId,
        inviteToSharePacket({
          packetId: sharePacket.id,
          recipients,
          message,
          canForward: true,
        })
      );

      await sharedPackets.refreshActivities(sharePacket);

      if (recipients.length === 1) {
        const fullName = getFullNameOrEmail(getKindData(recipients[0]).contact);
        ui.toast({
          message: 'Invitation Sent',
          description: `${fullName} has been invited to view this packet.`,
          type: 'success',
        });
      } else {
        ui.toast({
          message: 'Invitation Sent',
          description: `${recipients.length} recipients have been invited to view this packet.`,
          type: 'success',
        });
      }

      await this.props.onInvite({
        recipients,
        message,
      });
      this.props.onClose();
    } catch (err) {
      ui.wentWrong(err);
    }
  };

  render() {
    return (
      <BaseSharePacketInviteEmailModal
        {...this.props}
        onInvite={this.handleSaveInvite}
      />
    );
  }
}
